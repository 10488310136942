export default {
  'department': {
    baseline: 'department',
    variants: {
      plural: 'departments',
      short: 'dept',
    },
  },
  'program': {
    baseline: 'program',
    variants: {
      plural: 'programs',
      short: 'prog',
    },
  },
  'course': {
    baseline: 'course',
    variants: {
      plural: 'courses',
      short: 'cour',
    },
  },
  'catalog': {
    baseline: 'catalog',
    variants: {
      plural: 'catalogs',
      short: 'cat',
    },
  },
  'curriculum': {
    baseline: 'curriculum',
    variants: {
      plural: 'curriculum',
      short: 'curr',
    },
  },
  'course set': {
    baseline: 'course set',
    variants: {
      plural: 'course sets',
      short: 'course set',
    },
  },
  'credit': {
    baseline: 'credit',
    variants: {
      plural: 'credits',
      short: 'cr',
    },
  },
}
