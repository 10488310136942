import { useContext } from '@nuxtjs/composition-api'
import useFeatureFlags from './useFeatureFlags'
import { ENABLE_LOCALIZATION } from '@/constants/featureFlags'

export default function useLocalization() {
  const { app, store } = useContext()
  const featureFlags = useFeatureFlags({
    isLocalizationEnabled: ENABLE_LOCALIZATION
  })

  const initializeLocalization = async () => {
    try {
      if (!featureFlags.isLocalizationEnabled) {
        return
      }

      const school = store.state.settings.school

      if (!school) {
        console.warn('School information not available to setup localization')
        return
      }

      const response = await app.$axios.$get(`/${school}/general/localizations`)
      const localizationSettings = response?.localizations?.[response.deafult || 'en-us']
      
      app.$updateLocalization({
        localizations: localizationSettings.localizations || {},
        dateFormat: localizationSettings.dateFormat || '',
        timeFormat: localizationSettings.timeFormat || ''
      })

      return response
    } catch (error) {
      console.warn('Failed to fetch localizations:', error)
    }
  }

  return {
    initializeLocalization,
    ready: featureFlags.ready
  }
}
