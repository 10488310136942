<template>
  <div class="wrapper flex flex-col justify-between items-stretch">
    <component
      :is="element.component"
      v-for="(element, index) of schema"
      :key="index"
      :content="element.content"
    />
  </div>
</template>

<script>
import { LogoBlock, FreeFormBlock, NavBlock, RowBlock, NewBlock } from './SchemaLayoutBlocks.jsx'

export default {
  components: {
    LogoBlock,
    FreeFormBlock,
    NavBlock,
    RowBlock,
    NewBlock,
  },
  props: {
    schema: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>
.wrapper >>> p {
  @apply text-sm;
}

.wrapper >>> a {
  @apply text-sm;
}

.wrapper >>> a:hover {
  text-decoration: underline;
}
.foot-headers {
  font-weight: var(--footerElementsFontWeight, 600);
}

.foot-headers,
.wrapper >>> p,
.wrapper >>> a {
  color: var(--footerElementsFontColor, inherit);
  mix-blend-mode: difference; /* Auto-contrast */
}

.settings-loaded .foot-headers,
.settings-loaded .wrapper >>> p,
.settings-loaded .wrapper >>> a {
  mix-blend-mode: normal; /* Revert Auto-contrast */
}
</style>
