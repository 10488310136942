import { onGlobalSetup } from '@nuxtjs/composition-api'
import useLocalization from '~/composables/useLocalization'

export default function () {
  onGlobalSetup(() => {
    const { initializeLocalization, ready } = useLocalization()

    // Watch for feature flags readiness and initialize localization
    if (ready.value) {
      initializeLocalization()
    } else {
      // Watch for feature flags to become ready
      watch(ready, (isReady) => {
        if (isReady) {
          initializeLocalization()
        }
      })
    }
  })
}
