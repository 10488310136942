import Vue from 'vue'
import VueI18n from 'vue-i18n'
import localizationOptions from '@/constants/localizationOptions'
import virtualLocalizations from '@/constants/virtualLocalizations'
import merge from 'lodash/merge'
import mapValues from 'lodash/mapValues'
import moment from 'moment'
import _get from 'lodash/get'

const MATCH_PATTERN = /\{([^}]+)\}/g
const CLEAN_PATTERN = /^\{(.+)\}$/

Vue.use(VueI18n)

/**
 * Takes a pattern and values and creates output value
 * @function
 * @param {string} pattern - Interpolation pattern. e.g. "{course.variants.plural}"
 * @param {object} obj - Data source for dynamic values
 * @param {object} [options] - Options
 * @param {boolean} [options.fallbackToPattern=true] - Use part of pattern if value is not available
 * @return {string} Interpolated string
 */
export function getDynamicRender (pattern = '', obj = {}, options = {}) {
  const { fallbackToPattern = true } = options

  return pattern.replace(MATCH_PATTERN, (part) => {
    if (part) {
      const prop = part.replace(CLEAN_PATTERN, '$1')
      const value = _get(obj, prop)
      return value ?? (fallbackToPattern ? part : '')
    }
    return ''
  })
}

function extendedLocalization (sourceObj, virtuals) {
  return {
    ...sourceObj,
    ...mapValues(virtuals, (item) => {
      const newObject = {}
      if (item.baseline) {
        newObject.baseline = getDynamicRender(item.baseline, sourceObj)
      }
      if (item.variants) {
        newObject.variants = mapValues(item.variants, variant => getDynamicRender(variant, sourceObj))
      }
      return newObject
    }),
  }
}

function createCustomLocalizeFunction (originalFunction) {
  return (key, variant = 'baseline') => {
    let localizationKey
    switch (variant) {
      case 'baseline':
        localizationKey = `${key}.baseline`
        break
      default:
        localizationKey = `${key}.variants.${variant}`
        break
    }
    const translatedValue = originalFunction(localizationKey)
    // return original key if not translated
    return translatedValue === localizationKey ? key : translatedValue
  }
}

/**
 * Returns a localized date and time string based on the provided date, format, and options.
 *
 * @param {Date|string|number} dateTime - The date and time to be formatted.
 * @param {Object} dateTimeFormat - An object containing the date and time format strings.
 * @param {string} dateTimeFormat.dateFormat - The date format string.
 * @param {string} dateTimeFormat.timeFormat - The time format string.
 * @param {Object} [options] - An optional object containing additional options.
 * @param {string} [options.dateFallback=''] - The fallback date format string.
 * @param {string} [options.timeFallback=''] - The fallback time format string.
 * @param {string} [options.dateOverride=''] - The date format string to use instead of the provided format.
 * @param {string} [options.timeOverride=''] - The time format string to use instead of the provided format.
 * @param {boolean} [options.dateOnly=false] - Whether to return only the date string.
 * @param {boolean} [options.timeOnly=false] - Whether to return only the time string.
 * @param {string} [options.sourceFormat=''] - The format of the provided date string, if different from the default format.
 * @returns {string} The formatted date and time string.
 */
function getLocalizedDateTime (dateTime, dateTimeFormat, options = {}) {
  const {
    dateFallback = '',
    timeFallback = '',
    dateOverride = '',
    timeOverride = '',
    dateOnly = false,
    timeOnly = false,
    sourceFormat = '',
  } = options

  const dateFormat = dateOverride || dateTimeFormat.dateFormat || dateFallback || ''
  const timeFormat = timeOverride || dateTimeFormat.timeFormat || timeFallback || ''

  let formatString = `${dateFormat} ${timeFormat}`

  if (!timeFormat && !dateFormat) {
    return dateTime
  }

  if (dateOnly) {
    formatString = `${dateFormat}`
    if (!dateFormat) {
      return dateTime
    }
  }

  if (timeOnly) {
    formatString = `${timeFormat}`
    if (!timeFormat) {
      return dateTime
    }
  }

  const momentDateTime = sourceFormat ? moment(dateTime, sourceFormat) : moment(dateTime)
  return momentDateTime.format(formatString.trim())
}


const dateTimeFormat = Vue.observable({
  dateFormat: '',
  timeFormat: '',
})

const extendedLocalizationOptions = extendedLocalization(localizationOptions, virtualLocalizations)

const i18nParams = {
  locale: 'main',
  fallbackLocale: 'base',
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  messages: {
    main: extendedLocalizationOptions,
    base: extendedLocalizationOptions,
  },
}

const i18n = new VueI18n(i18nParams)

export function localizeDateTime (dateTime, options = {}) {
  return getLocalizedDateTime(dateTime, dateTimeFormat, options)
}

export const localize = createCustomLocalizeFunction(i18n.t.bind(i18n))

export function updateLocalization (updatedSettings = {}) {
  i18n.setLocaleMessage('main', extendedLocalization(merge({}, localizationOptions, updatedSettings.localizations || {}), virtualLocalizations))
  dateTimeFormat.dateFormat = updatedSettings.dateFormat
  dateTimeFormat.timeFormat = updatedSettings.timeFormat
}

const LocalizationPlugin = {
  install (Vue) {
    Vue.prototype.$localize = localize
    Vue.prototype.$localizeDateTime = localizeDateTime
    Vue.prototype.$updateLocalization = updateLocalization
    Vue.prototype.localize = localize
    Vue.prototype.localizeDateTime = localizeDateTime
    Vue.prototype.updateLocalization = updateLocalization
  },
}

export default function (context, inject) {
  // Initialize plugin
  Vue.use(LocalizationPlugin)
  // Inject the functions into the Nuxt context
  inject('localize', localize)
  inject('localizeDateTime', localizeDateTime)
  inject('updateLocalization', updateLocalization)
}
