export default {
  'courses': {
    baseline: '{course.variants.plural}',
  },
  'courseSets': {
    baseline: '{course set.variants.plural}',
  },
  'programs': {
    baseline: '{program.variants.plural}',
  },
  'Department(s)': {
    baseline: '{department.baseline}(s)',
  },
  'credit(s)': {
    baseline: '{credit.baseline}(s)',
  },
}
