// Usage: This plugin will add a class to the html element when the settings are loaded.
export default ({ store }) => {
    store.watch(
      (state) => state.settings.settingsLoaded,
      (loaded) => {
        if (loaded) {
          document.documentElement.classList.add('settings-loaded');
        }
      },
      { immediate: true }
    )
  }