import { getHost } from '../store/index'

export default function addDefaultHeaders({ $axios, req }) {
  $axios.onRequest((config) => {
    // req exists only on server-side, so we need to check for it in order to fill the Origin header.
    // On client-side the Origin is added automatically by the browser
    if (req) {
      const host = getHost(req)
      // needed for the hydration phase; we authenticate the catalog based on the Origin
      // and if the request is not made from the browser, the Origin header is not set
      config.headers.common['Origin'] = host
    }

    config.headers.common['X-Requested-With'] = 'catalog'
  })
}
